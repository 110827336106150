<template>
  <div class="list">
    <notifications></notifications>
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.von"
                        field="von"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.fromdate") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.bis"
                        field="bis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.untildate") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="$CONST('CONFIG').APPTYPE != 'DESKA'">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="$CONST('CONFIG').APPTYPE != 'DESKA'">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="termin-veranstaltung"
                      :label="$t('global.event')"
                      :veranstaltung="filters.veranstaltung"
                      :allowOpen="false"
                      editable
                      @confirmed="setVeranstaltung"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.raum"
                        :options="raeume"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.room')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.noroomfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.room") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filters.dozent"
                      :label="
                        $CONST('CONFIG').APPTYPE === 'DESKA'
                          ? $t('global.reservedfor')
                          : $t('global.Lecturer')
                      "
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :editable="true"
                      :tabindex="3"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="
                $CONST('CONFIG').APPTYPE === 'DESKA'
                  ? $t('global.reservations')
                  : $t('global.dateslist')
              "
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div
                    class="ml-4"
                    v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                  ></div>
                  <div class="ml-4" v-if="$CONST('CONFIG').APPTYPE != 'DESKA'">
                    <router-link
                      v-if="berechtigungen.m_ressourcen.create"
                      class="btn btn-success"
                      :to="{ name: 'termin', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                    <button
                      class="btn btn-primary ml-2"
                      @click="raumUpdaten"
                      v-if="berechtigungen.m_ressourcen.update"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-door-open"
                        class="mr-2"
                      />
                      {{ $t("global.changeroom") }}
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      @click="exportToExcel(listData, 'termine')"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.export") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_ressourcen.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDatumTermine"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td class="no-pointer" v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <termin-raum-update-modal
      :termine="selectedIds"
      @updated="raeumeGeaendert"
    />

    <loeschen-modal
      id="termin-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="terminLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import TerminRaumUpdateModal from "@/components/Modals/TerminRaumUpdateModal.vue";
import excelExport from "@/mixins/ExcelExport";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "Terminliste",
  components: {
    Navbar,
    HeadMenu,
    DatePicker,
    HeadMenuFilter,
    LoeschenModal,
    SingleSelectVeranstaltung,
    SingleSelectPerson,
    TerminRaumUpdateModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.dashboard_dates");
      },
    };
  },
  mixins: [page, dashboard, excelExport, filter],
  props: {},
  store,
  data() {
    if (this.$CONST("CONFIG").APPTYPE === "DESKA")
      return {
        filterRollen: this.$CONST("ROLLEN").MITARBEITER,
      };
    else
      return {
        filterRollen: this.$CONST("ROLLEN").DOZENTEN,
      };
  },
  computed: {
    fields() {
      if (this.$CONST("CONFIG").APPTYPE === "DESKA") {
        return [
          { key: "selected", label: "" },
          { key: "von", sortable: true, label: this.$t("global.from") },
          { key: "bis", sortable: true, label: this.$t("global.till") },
          {
            key: "von_uhrzeit",
            sortable: true,
            label: this.$t("global.start"),
          },
          { key: "bis_uhrzeit", sortable: true, label: this.$t("global.end") },
          {
            key: "veranstaltung.kuerzel",
            sortable: true,
            label: this.$t("global.booking"),
            formatter: (value, key, item) => {
              return value ? value : item.bezeichnung;
            },
          },
          {
            key: "raeumeleitsystem",
            sortable: true,
            label: this.$t("global.room"),
          },
          {
            key: "arbeitsplatz",
            sortable: true,
            label: this.$t("global.workplace"),
            formatter: (value) => {
              return value
                ? value
                    .map((arbeitsplatz) => arbeitsplatz.bezeichnung)
                    .join(", ")
                : "";
            },
          },
          {
            key: "dozenteneinsatzliste",
            sortable: true,
            label: this.$t("global.reservedfor"),
          },
        ];
      } else {
        return [
          { key: "selected", label: "" },
          { key: "von", sortable: true, label: this.$t("global.from") },
          { key: "von_uhrzeit", sortable: true, label: this.$t("global.Time") },
          { key: "bis", sortable: true, label: this.$t("global.till") },
          { key: "bis_uhrzeit", sortable: true, label: this.$t("global.Time") },
          {
            key: "veranstaltung.kuerzel",
            sortable: true,
            label: this.$t("global.event"),
            formatter: (value, key, item) => {
              return value ? value : item.bezeichnung;
            },
          },
          {
            key: "lektionen",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            label: this.$t("global.lessons"),
          },
          {
            key: "raeumeleitsystem",
            sortable: true,
            label: this.$t("global.room"),
          },
          {
            key: "dozenteneinsatzliste",
            sortable: true,
            label: this.$t("global.responsible"),
          },
          {
            key: "veranstaltung.anmeldungen",
            sortable: true,
            label: this.$t("global.participants"),
            thClass: "text-center",
            tdClass: "text-center",
          },
        ];
      }
    },
    queryParams: function () {
      var params = {};

      params.vonFilter = this.setQueryParam(this.filters.von);
      params.bisFilter = this.setQueryParam(this.filters.bis);
      params.vonUhrzeitFilter = this.setQueryParam(this.filters.von_uhrzeit);
      params.bisUhrzeitFilter = this.setQueryParam(this.filters.bis_uhrzeit);
      params.bez = this.setQueryParam(this.filters.bezeichnung);
      params.lektionenFilter = this.setQueryParam(this.filters.lektionen);

      params.filterCount = this.setQueryParam(this.filters.count);

      params.veranstaltungId = this.setQueryParam(
        this.filters.veranstaltung?.id
      );
      params.dozentFilter = this.setQueryParam(this.filters.dozent?.id);

      params.raum = this.setQueryParamList(this.filters.raum);

      params.raumFilter = this.setQueryParam(this.filters["raeumeleitsystem"]);

      params.dozentDescFilter = this.setQueryParam(
        this.filters["dozenteneinsatzliste"]
      );

      params.anmeldungenFilter = this.setQueryParam(
        this.filters["veranstaltung.anmeldungen"]
      );
      params.vKuerzelFilter = this.setQueryParam(
        this.filters["veranstaltung.kuerzel"]
      );

      return params;
    },
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
  },

  created() {
    if (this.raeume.length == 0)
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);

    this.initFilter("termine-liste", "termin/", true);
    this.dateCols = ["von", "bis"];
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (this.$CONST("CONFIG").APPTYPE === "DESKA") {
      this.setHeaders("global", [
        "",
        "from",
        "till",
        "start",
        "end",
        "booking",
        "room",
        "workplace",
        "reservedfor",
      ]);
    } else {
      this.setHeaders("global", [
        "",
        "from",
        "Time",
        "till",
        "Time",
        "event",
        "lessons",
        "room",
        "responsible",
        "participants",
      ]);
    }
  },
  methods: {
    setFilterDozent(dozent) {
      this.$set(this.filters, "dozent", dozent);
    },

    setVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
    },

    details(termine) {
      this.$router.push({
        name: "termin",
        params: { id: termine.id, anzeige: 0 },
      });
    },

    raeumeGeaendert() {
      this.resetLoading(this);
    },

    raumUpdaten() {
      this.$bus.$emit("openTerminRaumModal");
    },

    terminLoeschen() {
      Api.delete("termin/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "termin-loeschen-modal");
    },

    sortDatumTermine(datumA, datumB, key) {
      if (key != "von" && key != "bis") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolveTermin(key, datumA);
          b = this.resolveTermin(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    resolveTermin(path, obj = self, separator = ".") {
      var properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    },
  },
};
</script>

<style></style>
